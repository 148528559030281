import React, { useState } from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, navigate } from "gatsby"
import AllTestimonials from "../components/AllTestimonials"
import ContactButton from "../components/ContactButton"
import WESTFaq from "../components/WESTFaq"

// This function encodes the captured form data in the format that Netlify's backend requires
function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const West122020 = () => {
  const [fields, setFields] = useState({})

  const handleChange = e => {
    setFields({ ...fields, [e.target.name]: e.target.value })
  }
  const onSubmit = event => {
    // Prevent the default onSubmit behavior
    debugger
    event.preventDefault()
    // POST the encoded form with the content-type header that's required for a text submission
    // Note that the header will be different for POSTing a file
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": event.target.getAttribute("name"),
        ...fields,
      }),
    })
      // On success, redirect to the custom success page using Gatsby's `navigate` helper function
      .then(() => navigate("/thankyoumuch/"))
      // On error, show the error in an alert
      .catch(error => alert(error))
  }
  return (
    <Layout>
      <SEO
        title="WEST - Upcoming Cohort"
        description="Learn more about our upcoming WEST program."
      ></SEO>
      <Helmet>
        <script src="https://player.vimeo.com/api/player.js"></script>
        {/* <script src="https://www.google.com/recaptcha/api.js"></script> */}
      </Helmet>

      <div
        class="maxWidth pagePadding"
        style={{
          marginTop: "46px",
        }}
      >
        <div class="row">
          <center style={{ marginBottom: "40px" }}>
            <div class="pageSub2">
              Introducing{" "}
              <span style={{ color: "#d05c25", fontWeight: "bold" }}>WEST</span>
              , the most transformative{" "}
              <strong>team-building + upskilling</strong> experience for you and
              your team.
            </div>
          </center>

          <img src={`/images/WEST/wfh2.png`} alt="Working from home"></img>
        </div>

        <div
          style={{
            marginTop: "40px",
            marginBottom: "5px",
          }}
          class="topLine"
        >
          &nbsp;
        </div>
        <div
          style={{
            marginBottom: "40px",
          }}
          class="bottomLine"
        >
          &nbsp;
        </div>

        <div class="row-course">
          <div class="column-course-L">
            <div class="bigText" style={{ marginBottom: "15px" }}>
              The{" "}
              <Link class="gotoLink" to="/west">
                Working Effectively in Small Teams
              </Link>
              &nbsp;program is a remote (yet immersive) learning experience
              designed for mid to senior level managers and their teams.
            </div>

            <div class="bigText" style={{ marginBottom: "15px" }}>
              Unfortunately, training programs that <i>actually</i> help you to
              work successfully with others are rare. We know from experience
              that a typical team-building workshop seldom produces lasting
              improvement in skills or practices –{" "}
              <span style={{ color: "#d05c25", fontWeight: "bold" }}>
                but WEST is not your typical team-building workshop.
              </span>
            </div>

            <div class="bigText" style={{ marginBottom: "15px" }}>
              Over the course of 12 weeks, WEST participants can expect to{" "}
              <strong>boost productivity</strong>,{" "}
              <strong>expand communication</strong>, and{" "}
              <strong>cultivate trust</strong> in one another as they learn,
              practice, and play together.
            </div>
          </div>

          <div class="column-course-R" style={{ marginTop: "50px" }}>
            <div style={{ marginBottom: "25px", textAlign: "center" }}>
              <span class="pageTitle2"> Join the waitlist </span>
              <br />
              <span class="bigText">
                We'll notify you when enrollment opens for the next WEST cohort
              </span>
            </div>
            <form
              method="post"
              netlify-honeypot="bot-field"
              data-netlify="true"
              name="WEST-landing-page"
              action="/thankyoumuch"
              onSubmit={onSubmit}
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="WEST-landing-page" />
              <div class="field">
                <label class="label">First name*</label>
                <div class="control">
                  <input
                    type="text"
                    class="input"
                    name="firstName"
                    id="name"
                    required
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div class="field">
                <label class="label">Last name*</label>
                <div class="control">
                  <input
                    type="text"
                    class="input"
                    name="lastName"
                    id="name"
                    required
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div class="field">
                <label class="label">Email*</label>
                <div class="control">
                  <input
                    type="email"
                    class="input"
                    name="email"
                    id="email"
                    required
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div
                style={{
                  textAlign: "center",
                }}
                class="has-text-centered field "
              >
                <button
                  style={{
                    textAlign: "center",
                  }}
                  class="contactButton"
                  // data-sitekey="6Lfic7YZAAAAAI8FAowsfWuYi64oCHlSNfU9jOwP"   g-recaptcha
                  // data-callback="onSubmit"
                  // data-action="submit"
                  style={{ marginTop: "36px" }}
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>

        <div
          style={{
            marginTop: "40px",
            marginBottom: "5px",
          }}
          class="topLine"
        >
          &nbsp;
        </div>
        <div
          style={{
            marginBottom: "40px",
          }}
          class="bottomLine"
        >
          &nbsp;
        </div>
      </div>

      <div class="video-container">
        <center class="video">
          <iframe
            src="https://player.vimeo.com/video/559694744?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            style={{
              position: "relative",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
            }}
            title="WEST TESTIMONIALS"
          ></iframe>
        </center>
      </div>

      <div class="maxWidth pagePadding" style={{ marginBottom: "48px" }}>
        <AllTestimonials></AllTestimonials>

        <div
          style={{
            marginTop: "40px",
            marginBottom: "5px",
          }}
          class="topLine"
        >
          &nbsp;
        </div>
        <div
          style={{
            marginBottom: "40px",
          }}
          class="bottomLine"
        >
          &nbsp;
        </div>

        <WESTFaq></WESTFaq>
      </div>
    </Layout>
  )
}

export default West122020
